<template>
    <router-view></router-view>
</template>

<script>

export default {
    name: "ScreenLayout",
};
</script>

<style scoped></style>